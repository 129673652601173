import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './AboutMe.css';

class AboutMe extends Component {

    render() {
        return (
            <div className="about-container">
                <h1 className="about-title">About Me</h1>
                <div className="about-content">
                    <p>Hi there! I'm a software engineer with a passion for both technology and travel. Over the years,
                        I've had the incredible opportunity to explore various places and cultures, and one of my
                        favorite ways to connect with new places is through their local beers and cuisine.</p>
                    <p>This website is a labor of love, combining my technical skills with my love for adventure. It's a
                        map that showcases all the beers I've had in different locations around the world, with some
                        cool filters to help you navigate through my beer journey.</p>
                    <p>When I'm not coding or enjoying a good brew, you can find me planning my next trip or listening
                        to a good record. Thanks for stopping by, and I hope you enjoy exploring the map as much as I
                        enjoyed creating it!</p>
                    <p>Check out my <Link to="/records">record collection</Link> to see what I'm currently spinning!</p>
                </div>
            </div>
        )
    }
}

export default AboutMe;