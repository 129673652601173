import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import RecordGrid from "./components/RecordGrid";
import NowPlaying from "./components/NowPlaying";
import "./Records.css";

const DISCOGS_API_URL = `https://api.discogs.com/users/rjbogz/collection/folders/0/releases?token=JwpCrlqBHxjLpFlZRhfxbLaewaNolRZOBKZbPAbG&per_page=250&sort=artist`;
const API_BASE_URL = "https://rjbogz.com/api";

const Records = () => {
    const [discogsData, setDiscogsData] = useState({});
    const [nowPlaying, setNowPlaying] = useState(null);
    const [recordData, setRecordData] = useState({});

    useEffect(() => {
        const fetchDiscogsData = async () => {
            try {
                const response = await fetch(DISCOGS_API_URL);
                if (!response.ok) throw new Error("Failed to fetch Discogs data");
                const data = await response.json();

                const mappedData = {};
                data.releases.forEach((release) => {
                    const basicInfo = release.basic_information || {};
                    const labels = basicInfo.labels || [];
                    const formats = basicInfo.formats || [];

                    mappedData[release.id] = {
                        album: basicInfo.title || "Unknown Album",
                        artist: (basicInfo.artists && basicInfo.artists.length > 0) ? basicInfo.artists[0].name : "Unknown Artist",
                        cover: basicInfo.cover_image || "https://rjbogz.com/records/default.png", // Fallback image
                        labels: labels.length > 0 ? labels.map((label) => label.name).join(", ") : "N/A",
                        tracks: formats.length > 0
                            ? formats.map((format) => (format.descriptions || []).join(", ")).join(", ")
                            : "N/A",
                        discogs_id: release.id,
                    };
                });
                setDiscogsData(mappedData);
            } catch (error) {
                console.error("Error fetching Discogs data:", error);
            }
        };

        fetchDiscogsData();
    }, []);

    useEffect(() => {
        const fetchRecords = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/records`);
                if (!response.ok) throw new Error("Failed to fetch records");

                const data = await response.json();
                console.log("Fetched Record Data");
                setRecordData(data);
            } catch (error) {
                console.error("Error fetching records:", error);
            }
        };
        fetchRecords();
    }, []);

    useEffect(() => {
        const fetchRecordEvent = async () => {
            try {
                let eventResponse = await fetch(`${API_BASE_URL}/record-shelf-event`);

                if (eventResponse.status === 404) {
                    console.warn("No record event found, checking Home Assistant...");

                    try {
                        const response = await fetch(`${API_BASE_URL}/currently-playing`);
                        if (!response.ok) throw new Error("Failed to fetch currently playing record");
                        const currentlyPlaying = await response.json();

                        console.log("Fetched Currently Playing Record:", currentlyPlaying);

                        if (currentlyPlaying.album) {
                            // Fallback for missing recordData
                            const recordInfo = recordData[currentlyPlaying.album] || {
                                album: currentlyPlaying.album,
                                artist: currentlyPlaying.artist || "Unknown Artist",
                                cover: `https://rjbogz.com/records/${currentlyPlaying.cover}`,
                                discogs_id: currentlyPlaying.discogs_id || null,
                            };

                            console.log("Setting Now Playing Data:", recordInfo);

                            setNowPlaying({
                                record: currentlyPlaying.album,
                                album: recordInfo.album,
                                artist: recordInfo.artist,
                                cover: recordInfo.cover,
                                streamUrl: `${API_BASE_URL}/stream`,
                                status: "playing",
                            });
                        } else {
                            console.log("No currently playing record found.");
                        }
                    } catch (error) {
                        console.error("Error fetching currently playing record:", error);
                    }
                } else {
                    let event = await eventResponse.json();
                    console.log("Fetched Record Event:", event);

                    if (event.record && recordData[event.record]) {
                        setNowPlaying({
                            record: event.record,
                            album: recordData[event.record].album,
                            artist: recordData[event.record].artist,
                            cover: `https://rjbogz.com/records/${recordData[event.record].cover}`,
                            streamUrl: `${API_BASE_URL}/stream`,
                            status: event.status,
                        });

                        console.log("Now Playing Updated:", {
                            record: event.record,
                            album: recordData[event.record].album,
                            artist: recordData[event.record].artist,
                            cover: `https://rjbogz.com/records/${recordData[event.record].cover}`,
                            streamUrl: `${API_BASE_URL}/stream`,
                            status: event.status,
                        });
                    }
                }
            } catch (error) {
                console.error("Error fetching record event:", error);
            }
        };

        fetchRecordEvent();

        const interval = setInterval(fetchRecordEvent, 2000);
        return () => clearInterval(interval);
    }, [recordData]);

    const handlePlay = () => {
        setNowPlaying((prev) => ({ ...prev, status: "playing" }));
        const audio = new Audio(nowPlaying.streamUrl);
        audio.play();
    };

    const handleStop = () => {
        setNowPlaying((prev) => ({ ...prev, status: "stopped" }));
        // Optionally stop the stream
    };

    const handleResume = () => {
        setNowPlaying((prev) => ({ ...prev, status: "playing" }));
        const audio = new Audio(nowPlaying.streamUrl);
        audio.play();
    };

    return (
        <div className="Records">
            <Helmet>
                <title>My Record Collection</title>
                <link rel="icon" type="image/x-icon" href="/records-icon.ico" />
            </Helmet>
            <header>
                <h1>Record Collection</h1>
            </header>
            {nowPlaying && <NowPlaying nowPlaying={nowPlaying} onPlay={handlePlay} onStop={handleStop} onResume={handleResume} />}
            <RecordGrid
                records={recordData}
                discogsData={discogsData}
                onNowPlaying={setNowPlaying}
            />
        </div>
    );
};

export default Records;