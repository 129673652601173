import React from 'react';

const NowPlaying = ({ nowPlaying }) => {

    return (
        <div className="now-playing-popup">
            <center>Now Playing</center>
            <br></br>
            <img src={nowPlaying && nowPlaying.cover ? nowPlaying.cover : "https://rjbogz.com/records/default.png"}
                 alt={nowPlaying && nowPlaying.album ? nowPlaying.album : "Unknown Album"} />
            <div className="details">
                <h3>{nowPlaying && nowPlaying.album ? nowPlaying.album : "Unknown Album"}</h3>
                <p>{nowPlaying && nowPlaying.artist ? nowPlaying.artist : "Unknown Artist"}</p>
                <audio controls className="audio-player">
                    <source src={nowPlaying && nowPlaying.streamUrl ? nowPlaying.streamUrl : ""} type="audio/mpeg"/>
                    Your browser does not support the audio element.
                </audio>
            </div>
        </div>
    )
        ;
};

export default NowPlaying;
