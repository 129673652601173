import React from "react";

const RecordCard = ({ record, onClick }) => {
    return (
        <div className="card" onClick={onClick}>
            <img
                src={`https://rjbogz.com/records/${record.cover}`}
                alt={`${record.artist} - ${record.album}`}
            />
            <p>{`${record.artist} - ${record.album}`}</p>
        </div>
    );
};

export default RecordCard;
