import React, { useState } from "react";
import RecordCard from "./RecordCard";
import RecordModal from "./RecordModal";

function RecordGrid({ records, discogsData, onNowPlaying }) {
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [trackList, setTrackList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("artist"); // Default sort by artist
    const [currentAudio, setCurrentAudio] = useState(null); // Move audio state here

    const handleCardClick = async (id) => {
        const record = records[id];
        const discogsInfo = discogsData[record.discogs_id] || {};
        const combinedRecord = { ...record, ...discogsInfo };

        setSelectedRecord(combinedRecord);

        // Fetch track listing dynamically
        if (discogsInfo.discogs_id) {
            try {
                const response = await fetch(`https://api.discogs.com/releases/${discogsInfo.discogs_id}`);
                if (!response.ok) throw new Error("Failed to fetch track listing");
                const data = await response.json();

                // Map the tracks
                const tracks = data.tracklist.map((track) => {
                    // Attempt to match the track title with the video title
                    var matchingVideo = (data.videos && data.videos.length > 0)
                        ? data.videos.find(function (video) {
                            return video.title.toLowerCase().includes(track.title.toLowerCase());
                        })
                        : null;

                    return {
                        title: track.title,
                        duration: track.duration || "N/A",
                        position: track.position,
                        youtubeUrl: (matchingVideo && matchingVideo.uri) ? matchingVideo.uri : null
                    };
                });

                setTrackList(tracks);
            } catch (error) {
                console.error("Error fetching tracklist:", error);
                setTrackList([]); // Reset if fetch fails
            }
        }
    };

    // Filter records by search term
    const filteredRecords = Object.entries(records).filter(([id, record]) => {
        const lowerSearch = searchTerm.toLowerCase();
        return (
            record.artist.toLowerCase().includes(lowerSearch) ||
            record.album.toLowerCase().includes(lowerSearch)
        );
    });

    const stripLeadingArticles = (str) => {
        return str.replace(/^(the|a|an)\s+/i, "").trim();
    };

    const sortedRecords = filteredRecords.sort(([, a], [, b]) => {
        if (sortOption === "artist") {
            return stripLeadingArticles(a.artist).localeCompare(stripLeadingArticles(b.artist));
        } else if (sortOption === "album") {
            return stripLeadingArticles(a.album).localeCompare(stripLeadingArticles(b.album));
        }
        return 0;
    });

    return (
        <div className="record-grid">
            <div className="search-controls">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                >
                    <option value="artist">Sort by Artist</option>
                    <option value="album">Sort by Album</option>
                </select>
            </div>
            <div className="grid">
                {sortedRecords.map(([id, record]) => (
                    <RecordCard
                        key={id}
                        record={record}
                        onClick={() => handleCardClick(id)}
                    />
                ))}

                {selectedRecord && (
                    <RecordModal
                        record={selectedRecord}
                        trackList={trackList}
                        setTrackList={setTrackList} // Pass setTrackList here
                        currentAudio={currentAudio}
                        setCurrentAudio={setCurrentAudio}
                        onClose={() => {
                            if (currentAudio) {
                                currentAudio.pause(); // Stop audio when modal closes
                                setCurrentAudio(null);
                            }
                            setSelectedRecord(null);
                            setTrackList([]); // Clear tracklist when modal is closed
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default RecordGrid;
