import React, { useState, useEffect, useCallback } from "react";

const RecordModal = ({ record, trackList, onClose }) => {
    const [currentVideo, setCurrentVideo] = useState(null);

    const handlePlayTrack = (youtubeUrl) => {
        if (youtubeUrl) {
            setCurrentVideo(youtubeUrl);
        } else {
            alert("YouTube link not available for this track.");
        }
    };

    const closeVideo = () => {
        setCurrentVideo(null);
    };

    // Use useCallback to memoize the function
    const handleEscapeKey = useCallback(
        (event) => {
            if (event.key === "Escape") {
                if (currentVideo) {
                    closeVideo(); // Close video popup if it's open
                } else {
                    onClose(); // Close the modal if no video is open
                }
            }
        },
        [currentVideo, onClose] // Add dependencies
    );

    useEffect(() => {
        document.addEventListener("keydown", handleEscapeKey);
        return () => {
            document.removeEventListener("keydown", handleEscapeKey);
        };
    }, [handleEscapeKey]); // Include handleEscapeKey as a dependency

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close" onClick={onClose}>
                    &times;
                </button>
                <center><h2>{`${record.artist} - ${record.album}`}</h2></center>
                <img src={record.cover} alt={`${record.artist} - ${record.album}`}/>
                <p>
                    <strong>Labels:</strong> {record.labels || "N/A"}
                </p>

                <h3>Tracks:</h3>
                {trackList.length > 0 ? (
                    <ul>
                        {trackList.map((track, index) => (
                            <li key={index}>
                                <button
                                    className="play-button"
                                    onClick={() => handlePlayTrack(track.youtubeUrl)}
                                >
                                    <span role='img' aria-label='play'>▶️</span>
                                </button>
                                <span className="track-position">
                                    <strong>{track.position}. </strong>
                                </span>
                                <span className="track-title">{track.title}</span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No track listing available.</p>
                )}
            </div>

            {/* YouTube Video Popup */}
            {currentVideo && (
                <div className="video-popup">
                    <div className="video-content">
                        <button className="close-video" onClick={closeVideo}>
                            &times;
                        </button>
                        <iframe
                            width="560"
                            height="315"
                            src={currentVideo.replace("watch?v=", "embed/")}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecordModal;
